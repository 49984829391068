export const questionDataBJ = {
  sex: '',
  birth: '',
  height: '',
  weight: '',
  fat: '',
  waist: '',
  hip: '',
  area: { text: '江蘇省', code: '3200' },
  drink: '',
  smoke: '',
  habeat: '',
  vege: '',
  habsport: '',
  sleep: '',
  disease: [
    { kdis: 'K01', level: '0' },
    { kdis: 'K02', level: '0' },
    { kdis: 'K03', level: '0' },
    { kdis: 'K04', level: '0' },
    { kdis: 'K05', level: '0' },
    { kdis: 'K06', level: '0' },
    { kdis: 'K07', level: '0' },
    { kdis: 'K08', level: '0' },
    { kdis: 'K09', level: '0' },
    { kdis: 'K10', level: '0' },
    { kdis: 'K11', level: '0' },
    { kdis: 'K12', level: '0' },
    { kdis: 'K13', level: '0' },
    { kdis: 'K14', level: '0' },
    { kdis: 'K15', level: '0' },
    { kdis: 'K16', level: '0' },
    { kdis: 'K17', level: '0' },
    { kdis: 'K18', level: '0' },
    { kdis: 'K19', level: '0' },
  ],
  oper: [],
  famdis: [],
  examineList: [
    { ne: 'WBC', ve: '', ut: 'A' },
    { ne: 'HGB', ve: '', ut: 'A' },
    { ne: 'PLT', ve: '', ut: 'A' },
    { ne: 'FG', ve: '', ut: 'B' },
    { ne: 'HBA1C', ve: '', ut: 'A' },
    { ne: 'CHOL', ve: '', ut: 'B' },
    { ne: 'LDLC', ve: '', ut: 'B' },
    { ne: 'HDLC', ve: '', ut: 'B' },
    { ne: 'TG', ve: '', ut: 'B' },
    { ne: 'GOT', ve: '', ut: 'A' },
    { ne: 'GPT', ve: '', ut: 'A' },
    { ne: 'ALP', ve: '', ut: 'A' },
    { ne: 'ALB', ve: '', ut: 'B' },
    { ne: 'CRE', ve: '', ut: 'B' },
    { ne: 'UA', ve: '', ut: 'B' },
    { ne: 'EGFR', ve: '', ut: 'A' },
  ],
  examine: [
    { ne: 'WBC', ve: '', ut: 'A' },
    { ne: 'HGB', ve: '', ut: 'A' },
    { ne: 'PLT', ve: '', ut: 'A' },
    { ne: 'FG', ve: '', ut: 'B' },
    { ne: 'HBA1C', ve: '', ut: 'A' },
    { ne: 'CHOL', ve: '', ut: 'B' },
    { ne: 'LDLC', ve: '', ut: 'B' },
    { ne: 'HDLC', ve: '', ut: 'B' },
    { ne: 'TG', ve: '', ut: 'B' },
    { ne: 'GOT', ve: '', ut: 'A' },
    { ne: 'GPT', ve: '', ut: 'A' },
    { ne: 'ALP', ve: '', ut: 'A' },
    { ne: 'ALB', ve: '', ut: 'B' },
    { ne: 'CRE', ve: '', ut: 'B' },
    { ne: 'UA', ve: '', ut: 'B' },
    { ne: 'EGFR', ve: '', ut: 'A' },
  ],
}

export const questionDataAI = {
  sex: '',
  birth: '',
  height: '',
  weight: '',
  fat: '',
  waist: '',
  hip: '',
  area: { text: '江蘇省', code: '3200' },
  drink: '',
  smoke: '',
  habeat: '',
  vege: '',
  habsport: '',
  sleep: '',
  disease: [
    { kdis: 'K01', level: '0' },
    { kdis: 'K02', level: '0' },
    { kdis: 'K03', level: '0' },
    { kdis: 'K04', level: '0' },
    { kdis: 'K05', level: '0' },
    { kdis: 'K06', level: '0' },
    { kdis: 'K07', level: '0' },
    { kdis: 'K08', level: '0' },
    { kdis: 'K09', level: '0' },
    { kdis: 'K10', level: '0' },
    { kdis: 'K11', level: '0' },
    { kdis: 'K12', level: '0' },
    { kdis: 'K13', level: '0' },
    { kdis: 'K14', level: '0' },
    { kdis: 'K15', level: '0' },
    { kdis: 'K16', level: '0' },
    { kdis: 'K17', level: '0' },
    { kdis: 'K18', level: '0' },
    { kdis: 'K19', level: '0' },
  ],
  oper: [],
  famdis: [],
}

export const questionDataFour = {
  sex: '',
  birth: '',
  height: '',
  weight: '',
  waist: '',
  hip: '',
  examine: [
    { ne: 'FG', ve: '', ut: 'A' },
    { ne: 'HBA1C', ve: '', ut: 'A' },
    { ne: 'CHOL', ve: '', ut: 'A' },
    { ne: 'LDLC', ve: '', ut: 'A' },
    { ne: 'HDLC', ve: '', ut: 'A' },
    { ne: 'TG', ve: '', ut: 'A' },
    { ne: 'UA', ve: '', ut: 'A' },
  ],
  sbp: '',
  dbp: '',
}

export const convertData = (data, example) => {
  let keys = Object.keys(example)
  let newData = {}
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    if (data.hasOwnProperty(key)) {
      newData[key] = data[key]
    } else {
      newData[key] = example[key]
    }
  }
  return newData
}

export const isAgeValid = (birth, minDate, maxDate) => {
  const curDate = strToNum(getTarDate())
  const birthDate = strToNum(birth)
  console.log('curDate: ', curDate)
  console.log('birthDate: ', birthDate)
  const age = (curDate - birthDate) / 10000
  console.log('age:', age)

  if (age < minDate || age > maxDate) {
    return false
  }
  return true
}

export const getTarDate = (days = 0) => {
  let date = new Date()
  date.setDate(date.getDate() + days)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1 + '').padStart(2, '0')
  const day = (date.getDate() + '').padStart(2, '0')
  return `${year}/${month}/${day}`
}

export const strToNum = (str = '') => {
  if (str === '') {
    return 0
  }
  return Number(str.replace(/[^0-9.-]+/g, ''))
}
